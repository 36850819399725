<script>
import { mapActions } from "vuex";
import SectionTabs from "@/components/Section/SectionTabs.vue";

export default {
	components: { SectionTabs },
	data() {
		return {
			menuCustomization: [ {
				id: 10,
				text: "Logotipos y colores",
				icon: "fa-solid fa-fill-drip",
				url: "/customization/colors"
			}, {
				id: 20,
				text: "Gestión de módulos",
				icon: "fa-solid fa-table-cells-large",
				url: "/customization/modules"
			}, {
				id: 40,
				text: "Menú personalizado",
				icon: "fa-solid fa-arrow-up-right-from-square",
				url: "/customization/menu"
			}],
			tab_component: [{
				name: "Dashboard",
				route: "inicio-dash",
			}, {
				name: "Personalización",
				route: "Logotipos y colores",
			}],
			id_empresa: this.$ls.get("user").empresa[0].id_empresa,
		}
	},

	async created() {
		await this.getModulos();
	},

	methods: {
		...mapActions("customizationModule", ["getListaModulos", "getMenu"]),

		async getModulos() {
			const resMods = await this.getListaModulos();
			const resMenu = await this.getMenu();
			if (!resMods || !resMenu) {
				this.toastGenericError();
			}
		}
	},

}
</script>

<template>
	<section id="customization" class="content-container">
		<SectionTabs :tabs="tab_component"/>

		<div class="row align-items-center my-3">
			<div class="col-12">
				<h3 class="section-head-title ps-0 mb-3 mb-lg-0">
					Personaliza y modifica las distintas secciones de tu plataforma.
				</h3>
			</div>
		</div>
		<div class="card border-0 card-shadow">
			<div class="card-body">
				<div class="row">
					<div class="col-12 col-lg-3">				
						<div class="lw-list-menu">
							<router-link 
								v-for="item in menuCustomization" 
								:key="item.id"
								:to="item.url"
								class="lw-list-menu-item lw-list-menu-item-action"
							>
								<i :class="item.icon" class="me-2"></i>
								{{ item.text }}
							</router-link>
						</div>
					</div>
					<div class="col-12 col-lg-9">
						<RouterView/>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>